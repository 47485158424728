import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { phone } from 'phone';
import PropTypes from 'prop-types';
import { doLoginRequest } from '../../../../store/action/auth';
import Phone from '../../../../ui-core/Phone';
import Button from '../../../../ui-core/Button/Button';
import Ref from '../../../../ui-core/Ref';
import { MODAL_TYPE } from '../../../../constants/app';
import { SEGMENT_EVENTS, trackEvent } from '../../../../utils/Segment';
// import AppleLogin from '../../../../components/AppleLogin';
import GoogleLogin from '../../../../components/GoogleLogin';
import AppleLogin from '../../../../components/AppleLogin';
import { grecaptcha } from '../../../../services/recaptcha';

import './style.css';

const Login = props => {
  const [postData, setPostData] = useState({
    mobile: null,
    country_code: null,
  });
  const [isValidNumber, setValidNumber] = useState(false);
  const { isLoading } = useSelector(state => state.common);
  const [error, setError] = useState({ hasError: false, message: '' });
  const [, setBtnVisible] = useState({
    isVisible: false,
  });
  const dispatch = useDispatch();
  const { handleModalState, countryCode } = props;
  const handlePhoneOnChange = (phoneNumber, countryCode, countryData) => {
    const validData = phone(
      `${countryCode}${phoneNumber?.replace(/\D+/g, '')}`,
    );
    setValidNumber(validData.isValid);
    setPostData({
      mobile: phoneNumber?.replace(/\D+/g, ''),
      country_code: countryCode,
    });
    if (phoneNumber[0] === '0') {
      setError({
        ...error,
        hasError: true,
        message: '0 cannot be the first digit of your mobile number.',
      });
    } else if (!validData.isValid) {
      setError({
        ...error,
        hasError: true,
        message: 'Please enter a valid number.',
      });
    } else {
      setError({ ...error, hasError: false, message: '' });
    }
  };

  const handlePhoneOnSubmit = e => {
    e.preventDefault();
    grecaptcha.execute(token => {
      // trackSubmittedEvent('Login submit', {
      //   page: 'Home Page',
      //   section_title: 'Login Modal',
      //   button_title: 'SEND VERIFICATION CODE',
      //   phone: postData?.mobile,
      //   country_code: postData?.country_code,
      // });
      dispatch(
        doLoginRequest({
          data: {
            ...postData,
            type: 'login',
            token,
          },
          callback: () => handleModalState(MODAL_TYPE.OTP),
          page: props.page,
        }),
      );
      // trackDisplayedEvent('Display OTP Modal', {
      //   page: 'Home Page',
      //   section_title: 'OTP Modal',
      // });
    });
  };

  const handleSignupClick = () => {
    handleModalState(MODAL_TYPE.SIGNUP);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      // eslint-disable-line
      button_title: 'Signup Now',
      page: props.page || 'Home Page',
      section_title: `Login Modal`,
    });
    // trackDisplayedEvent('Display Sign up Modal', {
    //   page: 'Home Page',
    //   section_title: 'Sign up modal',
    // });
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13 && isValidNumber) handlePhoneOnSubmit(event);
  };

  return (
    <div className="form-h">
      <h1 className="login-h2 text-center">Login</h1>
      <p className="login-h3 text-center mb-4 mt-4 pb-3">
        {' '}
        Login with your mobile number
      </p>
      <Phone
        countryCode={countryCode}
        handleOnChange={handlePhoneOnChange}
        setBtnVisible={setBtnVisible}
        onKeyDown={handleKeyDown}
      />
      <div className="position-relative">
        <span className="text-danger error-text text-center d-block w-100">
          {error.hasError ? error.message : ''}
        </span>
      </div>
      <Button
        text="SEND VERIFICATION CODE"
        class={`OtpButton ${isLoading && ''}`}
        isDisabled={isLoading || !isValidNumber}
        isLoading={isLoading}
        onClick={handlePhoneOnSubmit}
      />
      <p className="new-sign-in-para">or</p>
      <div className="d-flex align-items-center justify-content-center">
        {/* <AppleLogin isLoading={isLoading} section={'Login'} /> */}
        <GoogleLogin
          isLoading={isLoading}
          section={'Login'}
          plan_id={props.plan_id}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <AppleLogin
          isLoading={isLoading}
          section={'Login'}
          plan_id={props.plan_id}
          handleModalState={handleModalState}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center signin-bottom-row">
        <h3 className="head3 m-0"> Not a member? </h3>
        <Ref
          class="login-link fz20 ms-2"
          onClick={() => handleSignupClick()}
          text="Sign Up Now"
        />
      </div>
    </div>
  );
};

Login.propTypes = {
  countryCode: PropTypes.string,
  handleModalState: PropTypes.func,
  page: PropTypes.string,
  plan_id: PropTypes.string,
};

export default Login;
